@import url(https://fonts.googleapis.com/css?family=Poppins);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;

    min-height: 300px;
    overflow-x: hidden;
}

a {
    color: black;
    text-decoration: none;
}

.galleryContainer {
    width: 100%;
    height: 80vh;
    position: relative;

}

.FullWidth {
    width: 100%;
    height: 100%;
}

.Absolute {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.Cover {
    background-repeat: no-repeat;
    background-size: cover;

}

.galleryText {
    color: white;

}

.galleryTextTitle {
    font-size: 45px;
}

.galleryTextDesc {
    font-size: 25px;
}

.TopBar {
    position: absolute;
    z-index: 1000;
    color: white;
    left: 20px;
    top: 10px;
    font-size: 13px;

}

.RoundItem {
    width: 220px;
    height: 220px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border: 1px solid rgba(10, 10, 10, 0.1);
    border-radius: 100%;
    -webkit-flex-direction: column;
            flex-direction: column;

    overflow: hidden;

    position: relative;

    margin: 7px;

    transition: all .15s ease-out;
    -moz-transition: all .15s ease-out;
    -webkit-transition: all .15s ease-out;

    cursor: pointer;
}

.HalfRound {
    border-radius: 15px;
}

.RoundItem h1 {
    font-size: 23px;
    margin: 0;
    padding: 0;
    z-index: 10;
    color: white;
}

.RoundItem p {
    font-size: 15px;
    z-index: 10;
    color: white;
}

.AlphaBlack {
    background-color: rgba(10, 10, 10, 0.6);
}

.Animation {
    transition: all .15s ease-out;
    -moz-transition: all .15s ease-out;
    -webkit-transition: all .15s ease-out;
}

.RoundItem:hover .AlphaBlack {
    opacity: 0;
}

.RoundItemAnimation:hover {
    border-radius: 15px;
}

.RoundItem:hover .Image {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
}

.RoundItem:hover h1 {
    opacity: 0;
    -webkit-transform: translateY(-60px);
            transform: translateY(-60px);
}

.RoundItem:hover p {
    padding: 10px;
    -webkit-transform: translateY(-60px);
            transform: translateY(-60px);
    background-color: rgba(10, 10, 10, 0.5);
    border-radius: 15px;
}

.hashTag {
    margin: 10px;
    font-weight: bold;
}

.ButtonAct {
    border-radius: 4px;
    margin-top: 15px;
    padding: 10px;
    background-color: #1178f3;
    color: white;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-size: 13px;
    font-weight: bold;
    height: 35px;

    transition: all .15s ease-out;
    -moz-transition: all .15s ease-out;
    -webkit-transition: all .15s ease-out;
}

.ButtonAct:hover {
    opacity: 0.7;
}

.ButtonActMain {
    border-radius: 7px;
    margin-top: 15px;
    padding: 20px;
    background-color: #e9107b;
    color: white;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-size: 13px;
    font-weight: bold;
    height: 25px;

    transition: all .15s ease-out;
    -moz-transition: all .15s ease-out;
    -webkit-transition: all .15s ease-out;
}

.ButtonActMain:hover {
    opacity: 0.7;
}

